@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.imgbox {
    display: grid;
    height: 100%;
}


.disclaimer{
    border-top:1px solid #000000;
    border-bottom:1px solid #000000;

    margin:5px 0;
    padding:3px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    font-size: 15px;
  }

  .introD{
    margin:5px;
    padding:15px;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
  }

#imageIntro{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    max-width: 99%;
    max-height: 99%;
}
.showStats{
    margin-top: 5px;
    margin-bottom: 5px;
}

.stats{
    padding: 10px;
}

.satImg{
    width: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.tableImg{
    width: 100px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain
}

.introButton{
    width: 100%;
    border: 1px solid #000000;
    font-size: 25px;
    color: #000000;
    margin-bottom: 10px;

}

.addNotes{
    font-size: 11px;
}

.tableCont{
    margin-left: auto;
    margin-right: auto;
}

sup {
    vertical-align: super;
    font-size: smaller;
  }

table{
    width: 80%;
    text-align: center;
    table-layout:fixed;

}

th{
    text-decoration: underline;
}

td {
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
    padding-top: 10px;

}

.footer{
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #d6d6ee;

}

.donateltcdiv{
    text-align: right;
    font-size: 14px;
}

.footericonltc{
    width: 15px;
    text-align: right;
    padding-right: 15px;
    vertical-align: middle;
}

.stats{
    display: flex;
    padding:15px;
    font-size: 14px;
    justify-content:center;
    align-items:center;
    
}

.statElements{
    border:1px solid black;
    margin-right: 10px;
    background-color: white;
    padding-right: 15px;
    padding-left: 15px;
}

.changelog{
    font-family: "Courier Prime", monospace;
    font-size: 13px;
    font-style: normal;
    border:1px solid black;
    background-color: white;
    padding: 15px;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

@media only screen and (max-device-width: 1000px)
 {
    *{
        font-size: 10px;

    }
    table{
        width: 100%;
    }

    #imageIntro {
        width: 90%;
        margin: auto;
    }
    .satImg{
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;

    }
    .tableImg{
        max-width: 100%;
        max-height: 100%;
    }

}


