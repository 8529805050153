@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700&display=swap');

body{
    background-color: #E5E5E8;
    font-family: 'Lato';
    padding-left: 10%;
    padding-right: 10%;
}

a:visited {
    color: #476C8E;
    text-decoration: none;
}

a {
    color: #476C8E;
    text-decoration: none;
}

@media only screen and (max-device-width: 1000px)
 {
body{
    padding-left: 0%;
    padding-right: 0%;
}
 }